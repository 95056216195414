import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActions } from '@mui/material';
import Box from '@mui/material/Box';


function ShowMore(props) {

  const [showMore, setShowMore] = useState(false);

  const text = props.text;

  return (
    <React.Fragment>
      <Typography variant="body2" paragraph>
        {showMore ? text : `${text.substring(0, 75)} ...`}
        <Button sx={{textTransform: 'none'}} size="small" component="span" variant="text" onClick={() => setShowMore(!showMore)}>
        {showMore ? "Свернуть" : "Раскрыть"}
      </Button>
      </Typography>

    </React.Fragment>
  );

}

function HramSaintCard(props) {

  return (
    <Card sx={{ maxWidth: '100%', marginTop: 2, display: 'flex' }}>

      <CardMedia
        component="img"
        sx={{ width: 150, maxHeight: 300 }}
        image={props.data.Icon}
        alt="Икона"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent >

          <Typography gutterBottom variant="h7" component="div">
            {props.data.Name}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary" paragraph>
            {props.data.Date}
          </Typography>

          <ShowMore text={props.data.Text}/>
      </CardContent>
      <CardActions>
        <Button size="large" href={props.data.URL} size="small">Подробнее</Button>
      </CardActions>
    </Box>




    </Card >
  );
}

export default HramSaintCard;
