 import HramHome from "./Components/HramHome";
 import HramGallery from "./AboutContent/HramGallery";
 import HramAdmin from "./Components/HramAdmin";
 import HramIcons from "./AboutContent/HramIcons";
 import HramAbout from "./AboutContent/HramAbout";
 import HramHistory from "./AboutContent/HramHistory";
 
 const SiteMap =
 {
         MainNav : [
             {
                 Key : "Home",
                 Element : HramHome,
                 Title : "Главная / Богослужения",
                 Href : "/",
                 IsMenuItem : true,
             },

             {
                Key : "About",
                Element : HramAbout,
                Title : "О Храме",
                Href : "/About",
                IsMenuItem : true,
            },

             {
                Key : "Icons",
                Element : HramIcons,
                Title : "Иконы и Мощи",
                Href : "/Icons",
                IsMenuItem : true,
            },

            {
                Key : "History",
                Element : HramHistory,
                Title : "История Храма",
                Href : "/History",
                IsMenuItem : true,
            },

            {
                Key : "Gallery",
                Element : HramGallery,
                Title : "Фото Галерея",
                Href : "/Gallery",
                IsMenuItem : true,
            },

             {
                Key : "Admin",
                Element : HramAdmin,
                Title : "Администрирование",
                Href : "/Admin",
                IsMenuItem : false,
            }
         ]
 }
 
 export default SiteMap;



