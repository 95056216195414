import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HramImage from '../Resources/hram.png'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

function HramAbout() {

    return (
        <Container>
            
            <Box sx={{ width: '100%', marginTop: 2, p: 2 }}>

                <Typography variant="h5">О Храме</Typography>

                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    Храм иконы Божией Матери «В СКОРБЕХ И ПЕЧАЛЕХ УТЕШЕНИЕ», построен на месте жилого дома №127 на ул.Мандрыковской, который был разрушен в результате взрыва бытового газа 13 октября 2007 года.<br />
                    
                    В Храме совершается Божественная литургия в Воскресные дни и праздники.
                    Каждую неделю в храме служатся молебны о здравии с чтением Акафистов святителю Спиридону Тримифунтскому, который известен своей помощью в материальном благополучии и иконе Божией матери "Всецарица", которая помогает молящимся в исцелении от онкологических заболеваний.
                    По просьбе прихожан в храма совершаются таинства Крещения и Венчания. <br />
                
                </Typography>

                <Typography variant="h5">Контакты</Typography>
                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    Украинская Православная Церковь, Днепропетровская Епархия.<br />
                    Храм иконы Божией Матери "В СКОРБЕХ И ПЕЧАЛЕХ УТЕШЕНИЕ" <br />
                    Адрес: 49094, г. Днепр, Ж/М Победа-1, ул. Мандрыковская, 127А <br />
                    Тел: <b>+38(068)713-76-05</b> <br />

                    Настоятель: Протоиерей <b>Анатолий Хребтов</b> <br />
                </Typography>

                <Typography variant="h5">Пожертвования</Typography>
                
                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    Пожертвования  можно перевести на одну из карт на имя настоятеля храма:<br />
                    Monobank.<br />
                    Карта: 4441114450384688<br />
                    <Link href="https://send.monobank.ua/836N9umYh5" noinderline>Ссылка для перевода онлайн</Link>
                    <br />
                    Приватбанк. <br />
                    Карта: 4731219648888141 <br />
                    <Link href=" https://www.privat24.ua/rd/transfer_to_card/?hash=rd%2Ftransfer_to_card%2F%7B%22from%22%3A%22%22%2C%22to%22%3A%224731219648888141%22%2C%22amt%22%3A%22100%22%2C%22ccy%22%3A%22UAH%22%7D" noinderline>Ссылка для перевода онлайн</Link>
                    <br />

                    Или используя банковские реквизиты храма: UA653510050000026007878840977 Код ОКПО: 35680562 <br />

                </Typography>

                <Typography variant="h5">Карта</Typography>
                <Box sx={{ width: '100%', marginTop: 1, marginBottom: 2}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.372433663242!2d35.064564041464806!3d48.43444701004617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbfd4d2445d727%3A0x18cbf13a6b312a14!2z0KDQtdC70ZbQs9GW0LnQvdCwINCz0YDQvtC80LDQtNCwICLQpdGA0LDQvCDRltC60L7QvdC4INCR0L7QttC-0Zcg0JzQsNGC0LXRgNGWICLQoyDRgdC60L7RgNCx0L7RgtCw0YUg0ZYg0L_QtdGH0LDQu9GP0YUg0KPRgtGW0YjQsNC90L3RjyIg0L_QsNGA0LDRhNGW0Zcg0JTQvdGW0L_RgNC-0L_QtdGC0YDQvtCy0YHRjNC60L7RlyDRlNC_0LDRgNGF0ZbRlyDQo9C60YDQsNGX0L3RgdGM0LrQvtGXINCf0YDQsNCy0L7RgdC70LDQstC90L7RlyDQptC10YDQutCy0Lgg0LIg0LwuINCU0L3RltC_0YDRliI!5e0!3m2!1sen!2suk!4v1661098981958!5m2!1sen!2suk&language=ru" width="100%" height="250" loading="lazy"></iframe>
                </Box>

                <Typography variant="h5">Использованые материалы</Typography>
                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    Данный сайт является некомерческим и для удобства прихожан использует материалы со следующих ресурсов <br />
                
                    <Link href="https://azbyka.ru" >Православный Портал "Азбука Веры" <br /></Link>
                    <Link href="https://www.pravoslavie.ru" >Православие.Ru<br /></Link>
                    <Link href="https://gorsovet.com.ua" >Горсовет<br /></Link>
                    <Link href="https://opentv.media" >Відкритий <br /></Link>
                </Typography>

            </Box>
        </Container>

    );
}

export default HramAbout;