import React from "react";

import SiteMap from "../SiteMap.js";

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

import CrossImage from '../Resources/cross.gif'

import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
    Link as RouterLink
} from "react-router-dom";

function HramAppBar() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Router>
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>                    

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {SiteMap.MainNav.filter(page => page.IsMenuItem).map((page) => (
                                <MenuItem key={page.Key} onClick={handleCloseNavMenu} component={RouterLink} to={page.Href}>
                                    {page.Title}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
  
                    <Box component="img" sx={{  }} href="/" alt="Крест" src={CrossImage}/>
                    
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {SiteMap.MainNav.filter(page => page.IsMenuItem).map((page) => (
                            <Button
                                key={page.Key}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                component={RouterLink}
                                to={page.Href}
                            >
                                {page.Title}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>

        <Switch>
        {SiteMap.MainNav.map((page) => (
            <Route key={page.Key} path={page.Href} element = {page.Element()}>
            </Route>
        ))}
        </Switch>

        </Router>
    );
};

export default HramAppBar;