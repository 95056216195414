import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HramImage from '../Resources/hram.png'
import CardMedia from '@mui/material/CardMedia';

import victims from "./HramTragedyList.json";

import Tragedy1Image from '../Resources/Tragedy1.png'
import Tragedy2Image from '../Resources/Tragedy2.png'
import Tragedy3Image from '../Resources/Tragedy3.png'
import MonumentImage from '../Resources/Monument.png'

function HramHistory() {

    return (
        <Container>
            <Box sx={{ width: '100%', marginTop: 2, p: 2 }}>

                <Typography variant="h5" gutterBottom>Взрыв на улице Мандрыковская</Typography>

                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    В то злополучное субботнее утро 13 октября 2007 года около 10:30 в третьем подъезде жилого дома прогремел сильный взрыв.

                    Третий подъезд дома в результате взрыва был практически полностью разрушен, четвертый со временем демонтировали.
                    За считаные секунды под обломками оказались десятки жильцов.
                    Развалины разбирали вручную на протяжении 7 дней, пока не нашли тело последнего погибшего.
                    Далее в ход пошла техника.
                    23 человека погибли, среди них были и дети (самому маленькому было 6 лет).
                    Всего пострадали 417 человек. <br />
                </Typography>

                <Box component="img" sx={{ m: 'auto', display: 'table', width: 600, maxWidth: '100%' }} alt="Взрыв" src={Tragedy1Image} />


                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    <br /> Для помощи пострадавшим в здании соседней школы городские и областные власти развернули полевой штаб.
                    В мобильном штабе собирали всю информацию о пострадавших и передавали родственникам, близким, местным властям.

                    Спасатель Сергей Коваль стал одним из тех, кто первым оказался на месте взрыва.
                    Мужчина был заместителем начальника районной службы спасения.
                </Typography>

                <Typography variant="body2" color="text.secondary" align="justify" paragraph gutterBottom>
                    «Приехав на место происшествия, спасатели сразу же начали обследовать территорию.
                    Здесь перед глазами была ужасная картина. Мы спасали пострадавших на машине с башней, которую передвигали вручную.
                    Поскольку время измерялось секундами, мы шли на нарушение нормативных актов.
                    Спасательно-поисковые работы проводили около месяца (до последнего найденного человека)», — рассказал спасатель Сергей Коваль.
                </Typography>

                <Box component="img" sx={{ m: 'auto', display: 'table', width: 600, maxWidth: '100%' }} alt="Взрыв" src={Tragedy2Image} />


                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    <br /> Как рассказал житель соседнего дома Виталий, в тот день он будто наблюдал картину из фильма ужасов.
                </Typography>

                <Typography variant="body2" color="text.secondary" align="justify" paragraph gutterBottom>
                    «Помню, я тогда был совсем маленьким.
                    Очень испугался, когда увидел, что часть стен соседнего дома складывается как карточный домик.
                    У нас в квартире задрожали окна, но чудом треснули.
                    Знаю, что у тех, кто жил ближе, стекла просто повылетали. Ужасный день.
                    До сих пор не могу представить, что пришлось пережить родственникам погибших», – вспоминает Виталий.
                </Typography>

                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    Елена Кукла в тот день потеряла 13-летнюю Настю.
                    Девочка должна была пойти в субботу на занятия по английскому, но упросила маму пойти вместо английского к подружке, которая жила в доме №127 по ул. Мандрыковской.
                </Typography>

                <Typography variant="body2" color="text.secondary" align="justify" paragraph gutterBottom>
                    "Настя ​​мечтала учиться в Лондоне, поэтому не пропускала занятий по английскому языку.
                    Но в тот день попросила разрешения сходить к подружке", - вспоминает еле сдерживая слезы мать погибшей девочки Елена Кукла.
                    Настю и ее подружку Сашу хоронили в белых платьях, как невест. Некоторое время после смерти дочери Елена и сама не хотела жить.
                    Но однажды во сне к ней пришла Саша и попросила маму не делать того, что задумала Елена.
                </Typography>

                <Box component="img" sx={{ m: 'auto', display: 'table', width: 600, maxWidth: '100%' }} alt="Взрыв" src={Tragedy3Image} />

                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    <br /> А вот жительницу дома Веру Косицкую спасло лишь то, что она в тот день уехала на дачу.
                    Женщина до сих пор благодарит судьбу за возможность каждый день просыпаться по утрам, ведь тот день мог стать для неё последним.
                </Typography>

                <Typography variant="body2" color="text.secondary" align="justify" paragraph gutterBottom>
                    «Если бы я осталась дома, то и я бы погибла. Те, кому удалось выжить, похоронили 23 человека.
                    После трагедии мы остались без вещей и документов и жили в гостинице. Боль не передать словами.
                    Прошло время, и все немного забывается, но в этот день я всегда прихожу на место трагедии», — поделилась Вера Косицкая в день 13-й годовщины трагедии.
                </Typography>

                <Typography variant="h5" gutterBottom>На месте разрушеного дома построили храм</Typography>

                <Typography variant="body1" align="justify" paragraph gutterBottom>

                    На месте разрушенного дома построили храм Иконы Божией Матери "В Скорбех и Печалех Утешение".
                    Строительство храма было начато в 2008 году, а 7 января 2009 года было совершено первое богослужение.
                    Настоятель храма протоиерей Анатолий Хребтов, пришедший в него перед второй годовщиной трагедии, в октябре 2009 года, служит в нем и по сей день и с Божией помощью вместе со всеми прихожанами молится о погибших и помогает людям обращаться к Богу.
                    В Храме совершается Божественная литургия в Воскресные дни и праздники, а также молебны, панихиды и таинства крещения и венчания по просьбе прихожан.

                    <br />

                    Храм неустанно молится о погибших, их семьях и близких. Полный список погибших приведен ниже:

                    {victims.Names.map((t) => (
                        <Typography>{t}</Typography>
                    ))}

                    <br /><b>Царствие Небесное и Веченая Память погибшим!</b> <br />

                </Typography>


                <Box component="img" sx={{ m: 'auto', display: 'table', width: 600, maxWidth: '100%' }} alt="Взрыв" src={MonumentImage} />

                <br />
                <Typography variant="h5" gutterBottom>О трагедии был снят фильм</Typography>

                <Typography variant="body1" align="justify" paragraph gutterBottom>
                    В 2012 году, к пятой годовщине трагедии, режиссер Снежана Потапчук сняла документальный фильм, где рассказала всё о событиях 13 октября 2007 года.
                    Она записала воспоминания очевидцев.
                </Typography>

                <CardMedia sx={{ m: 'auto', display: 'table', width: 600, height: 300, maxWidth: '100%' }} component="iframe" src='https://www.youtube-nocookie.com/embed/4iA5hGv8tM0' allow="fullscreen" />

            </Box>
        </Container>

    );
};



export default HramHistory;
