import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


import TitleImage from '../Resources/title.png'


function HramHome() {
    return (

        <Container>
            <Box sx={{ width: '100%', marginTop: 2, p: 2}}>

            <Box component="img" sx={{width: '100%'}} alt="Храм" src={TitleImage}/>

                <Typography variant="h5">Дорогие Прихожане!</Typography>
                
                <Typography variant="body1" align="justify" paragraph gutterBottom>
                Новый сайт Храма Иконы Божией Матери "В Скорбех и Печалех Утешение" находится в активной разработке и сейчас запущен в тестовом режиме.
                В настоящий момент на сайте доступна лишь общая информация о храме.
                В ближайшее время на сайте будет добавлена возможность смотреть актуальное расписание богослужений и другой полезный функционал.
                </Typography>
            </Box>    
        </Container>
        
    );
  }

  export default HramHome;