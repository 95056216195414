import create from "zustand";
import axios from "axios";

const hramCrudStore = (objectType) => {

  const urls = {
    EventType: '/api/EventType/',
    ScheduleEvent: '/api/ScheduleEvent/',
    Occasion: '/api/Occasion/',
  };

  const serverUrl = (process.env.NODE_ENV === 'production' ? 'https://api.uteshenie.com.ua' : '') + urls[objectType];
  const storeFunc = create((set) => ({

    data: [],
    loading: false,
    hasErrors: false,

    createSoft: async (eventType) => {
      try {
        set((state) => ({ data: [...state.data, { ...eventType, IsNew: true }] }));
      } catch (err) {
        set(() => ({ hasErrors: true, loading: false }));
      }
    },

    update: async (row) => {
      try {
        set(() => ({ loading: true }));

        if (row.IsNew === false) {
          const response = await axios.put(
            serverUrl + row.id,
            row
          );

          set((state) => ({ data: state.data.map(t => t.id === row.id ? row : t), loading: false }));
        }
        else {
          const response = await axios.post(
            serverUrl,
            row
          );

          set((state) => ({ data: state.data.map(t => t.id === row.id ? { ...row, id: response, IsNew: false } : t), loading: false }));
        }

      } catch (err) {
        set(() => ({ hasErrors: true, loading: false }));
      }
    },

    delete: async (row) => {
      try {

        if (!row.IsNew) {
          set(() => ({ loading: true }));

          const response = await axios.delete(
            serverUrl + row.id
          );
        }

        set((state) => ({ data: state.data.filter(t => t.id !== row.id), loading: false }));
      } catch (err) {
        set(() => ({ hasErrors: true, loading: false }));
      }
    },

    fetch: async () => {

      //console.log(process.env);

      set(() => ({ loading: true }));
      try {
        const response = await axios.get(
          serverUrl
        );
        set((state) => ({ data: (state.data=response.data).map(t => Object.assign(t,{IsNew: false})), loading: false }));
      } catch (err) {
        set(() => ({ hasErrors: true, loading: false }));
      }
    },
  }));
  return storeFunc;
};

export default hramCrudStore;