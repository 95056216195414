import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import saintData from "./HramSaints.json";
import HramSaintCard from './HramSaintCard';

import TitleImage from '../Resources/icon.png'


function HramIcons() {

return (
    <Container>
        <Box sx={{ width: '100%', marginTop: 2, p: 2}}>

            <Typography variant="h5" gutterBottom>Храмовая Икона</Typography>

            <Typography variant="body2" color="text.secondary" gutterBottom>Празднование: 2 Декабря</Typography>

            <Typography variant="body1" align="justify" paragraph gutterBottom>
            Обстоятельства первоначального происхождения образа Божией Матери «В скорбех и печалех Утешение» неизвестны, но письмо иконы свидетельствует о том, что она очень древняя. <br/>
            
            Есть предание, что икона принадлежала цареградскому патриарху святителю Афанасию III Пателарию, с которым она побывала во всех скитаниях и путешествиях владыки и в 1653 году впервые прибыла в Россию.
            После кончины святителя в 1654 году икона была доставлена в Афонский монастырь Ватопед, где и пробыла до октября 1849 года, когда был открыт русский Андреевский скит.
            Тогда проживавший на покое в Ватопеде митрополит Григорий передал икону новооткрываемому скиту как благословение от обители.
            Образ стал келейной святыней основателя русского Андреевского скита на Афоне иеросхимонаха Виссариона (Вавилова).
            Когда 11 октября 1849 года последовало открытие этого скита, Виссарион благословил братию образом со словами: «Да будет сия икона вам отрадою и утешением в скорбях и печалях».  <br/>

            Прославление этого образа совершилось в России, в Вятской губернии, в 1863 году, когда в город Слободской этой губернии прибыл с Афона иеромонах Паисий, привезший с собой икону Богоматери «В скорбех и печалех Утешение».
            Образ этот украшен был богатой серебряной с позолотой ризой с бриллиантовым венцом на главе Богоматери. Он был помещен в женском Христорождественском монастыре, в храме Рождества Христова.
            Когда о. Паисий уже собирался возвратиться на Святую гору, от иконы Пресвятой Богородицы произошло чудесное исцеление 18-летнего немого юноши, после чего к образу началось настоящее паломничество.
            Многие страждущие богомольцы получили исцеление и утешение в своих печалях от святого образа в те дни.
            </Typography>

            <Box component="img" sx={{m: 'auto', display: 'table', width: 600, maxWidth: '100%'}} alt="Храм" src={TitleImage}/>

            <Typography variant="body1" align="justify" paragraph gutterBottom>
            <br/>
            Затем святая икона с большими почестями была перенесена в женский Преображенский монастырь г. Вятки, а в Слободском остался точный список с чудотворного образа, выполненный тем самым исцеленным 18-летним юношей.
            Духовный подъем вятчан был настолько велик, что «Вятские губернские ведомости» накануне Великорецкого крестного хода сравнивали его по религиозному настрою со встречей афонской святыни.<br/>
            
            О. Паисий, отправляясь на Афон, оставил в Преображенском монастыре список чудотворной иконы, а 26 июня 1871 года во имя иконы «В скорбех и печалех Утешение» в Вятке был заложен храм, который строился более 11 лет. 31 августа 1882 года,
            в день Положения Честного пояса Пресвятой Богородицы, архиепископ Апполос освятил храм, в котором каждую субботу перед образом читался акафист.
            Раз в два года, в память о пребывании святой иконы в Вятском крае и происходивших от нее чудесных исцелениях, совершались торжественные крестные ходы по всей епархии.<br/>
            
            19 ноября 1866 года в Слободском Христорождественском монастыре на список чудотворного образа Божией Матери «В скорбех и печалех Утешение» была торжественно возложена присланная с Афона позолоченная риза, а в воспоминание первого исцеления от иконы (немого юноши, который и выполнил список), в монастыре совершалась особо торжественная служба. В этот день и было установлено празднование в честь чудотворного образа Пресвятой Богородицы. 
            27 марта 1890 года святыня была доставлена в Россию и помещена в Санкт-Петербурге в Благовещенском соборе при подворье Андреевского скита. В самой обители была оставлена ее точная копия. Граждане города день и ночь спешили толпами поклониться чудотворному образу. Благодать Божия тогда же изволила прославить икону Богоматери многочисленными чудотворениями.
            
            Сейчас образ хранится в алтаре санкт-петербургского Николо-Богоявленского морского собора.
            Икона выносится для поклонения раз в год перед вечерним богослужением накануне 2 декабря и возвращается в алтарь 2 декабря.
            Чтимый список со святой иконы пребывает в Свято-Екатерининском соборе г. Слободского. В 1999 году престол одного из храмов бывшего мужского Крестовоздвиженского монастыря в Слободском был освящен в честь чудотворного образа.<br/>
            
            В Алексиево-Акатовом женском монастыре г. Воронежа также хранится список с афонской святыни, на котором написано: «сия икона написана и освящена на святой Афонской Горе в Русской Обители Святого Иоанна Златоустого при настоятеле иеросхимонахе Кирилле 1905 года».
            В 1999 году икона замироточила. Икона «В скорбех и печалех Утешение» устроена в виде створчатого складня.
            Кроме Пречистой Богоматери, на ней изображены святые великомученики Георгий Победоносец и Димитрий Солунский на конях, святой Иоанн Предтеча и святой апостол и евангелист Иоанн Богослов, преподобные Антоний, Евфимий, Онуфрий, Савва Освященный, святители Спиридон Тримифунтский и Николай Чудотворец.
            Образ украшен несколькими ризами, одна из которых – золотая.<br/>

            </Typography>

            <Typography variant="h5">В храме находятся частички мощей следующих святых</Typography>

            {saintData.Saints.map((item) => (
                <HramSaintCard data={item} />
            ))}

            <Typography variant="body1">
                <br /> Ковчег со святыми мощами выносится для поклонения во время еженедельного молебна святителю Спиридону Тримифунтскому, а также в дни памяти этих святых.
            </Typography>

        </Box>
    </Container>

);
}

export default HramIcons;