import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from '@mui/material/Container';


function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = {
      img: r(item),
      cols: item.includes("-h") ? 2 : 1,
      rows: item.includes("-v") ? 2 : 1,
    };
  });
  return images;
}

function compareNumbers(a, b) {
  const numberPattern = /\d+/g;
  return a.match(numberPattern) - b.match(numberPattern);
}

const images = importAll(require.context('../Resources/Gallery', false, /\.(png|jpe?g|svg)$/));

function HramGallery() {

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <Container>
      <Box sx={{ width: '100%', height: '100%', marginTop: 2, p: 2 }}>
        <Typography variant="h5">Фотогалерея Храма</Typography>

        <ImageList cols={2} variant="quilted">
          {Object.keys(images).sort((a, b) => compareNumbers(a, b)).map((item) => (
            <ImageListItem key={item} cols={images[item].cols || 1} rows={images[item].rows || 1}>
              <img
                {...srcset(images[item].img, 164, images[item].rows, images[item].cols)}
                alt={"Фотография"}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

      </Box>
    </Container>
  );
}

export default HramGallery;