import './App.css';

import React from "react";
import HramFooter from './Components/HramFooter.js';
import HramAppBar from './Components/HramAppBar.js';
import { CssBaseline, Typography } from '@mui/material';
import Cookies from 'js-cookie';

function App() {
  
  return (

  <React.Fragment>
    <CssBaseline />
    <HramAppBar />
    <HramFooter />
  </React.Fragment>

  );

}

export default App;