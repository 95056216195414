import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
            Храм иконы Божией Матери "В скорбех и печалех Утешение" 
            {' Copyright ' + new Date().getFullYear()} <br />
        <Link color="inherit" href="http://www.eparhia.dp.ua">
          Днепропетровская Епархия
        </Link>
        <br />
        <Link color="inherit" href="http://pravoslavye.org.ua">
          Украинская Православная Церковь.
        </Link>

      </Typography>
    );
  }

  function HramFooter(){
    return (
      <Box sx={{ width: '100%', marginTop: 2, p: 2 }}>
          <Divider sx={{ m: 2 }} variant="middle" />
          <Copyright />
      </Box>
    );
  }

  export default HramFooter;