import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import FullFeaturedCrudGrid from "./HramTable";
import useStore from '../Stores/HramCrudStore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Container>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function HramAdmin() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Расписание" {...a11yProps(0)} />
          <Tab label="Виды Богослужений" {...a11yProps(1)} />
          <Tab label="Праздники" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <HramScheduleTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HramEventTypeTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HramOccasionTable />
      </TabPanel>
    </Box>
  );
}

function HramScheduleTable(){
  
  const columns = [
    { field: 'EventStart', headerName: 'Start', newValue: '' , width: 180, editable: true },
    { field: 'Priority', headerName: 'Order', newValue: 1, type: 'number', width: 100, editable: true },
    { field: 'EventType_id', headerName: 'Event Type', newValue: '', type: 'dateTime', width: 100, editable: true },
    { field: 'Title', headerName: 'Title', newValue: '', width: 180, editable: true },
    { field: 'Comment', headerName: 'Comment', newValue: '', width: 180, editable: true },
];

  return (
      <FullFeaturedCrudGrid columns={columns} store={useStore('ScheduleEvent')} />
  );
}

function HramEventTypeTable(){
    const columns = [
        { field: 'Name', headerName: 'Name', newValue: '<Name>' , width: 180, editable: true },
        { field: 'Description', headerName: 'Description', newValue: '', width: 380, editable: true },
        { field: 'Url', headerName: 'URL', newValue: '', width: 180, editable: true },
    ];
    
    return (
        <FullFeaturedCrudGrid columns={columns} store={useStore('EventType')} />
    );
}

function HramOccasionTable(){
    const columns = [
        { field: 'Name', headerName: 'Name', newValue: '<Name>' , width: 180, editable: true },
        { field: 'Description', headerName: 'Description', newValue: '', width: 380, editable: true },
        { field: 'Url', headerName: 'URL', newValue: '', width: 180, editable: true },
        { field: 'MainImageUrl', headerName: 'ImageURL', newValue: '', width: 180, editable: true },
    ];
    
    return (
        <FullFeaturedCrudGrid columns={columns} store={useStore('Occasion')} />
    );
}

export default HramAdmin;